import React from 'react';
import Styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const OptionComponent = ({title, icon, onClick}) => {
    const handleClick = () => {
        onClick(title);
    }

    return(
        <>
            <Main className="text-[#2c3e50] hover:text-[#2980b9]" onClick={handleClick}>
                <div className="w-5">
                    <FontAwesomeIcon icon={icon}></FontAwesomeIcon>   
                </div>
                <p className="ml-4">{title}</p>
            </Main>
        </>
    )
}

const Main = Styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 10px;
    margin-left: 15px;
    cursor: pointer;
`;

export default OptionComponent;