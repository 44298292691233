import React from 'react';
import Styled from 'styled-components';

const ResultComponent = (props) => {
    return(
        <>
            <Main className="flex flex-col shadow-lg rounded-md">
                <p className="font-semibold">{props.tags.name}</p>
                <p className="italic">{props.tags.amenity}</p>
                
            </Main>

        </>
    )
}

const Main = Styled.div`
    padding: 10px;
    width: 600px;
    background-color: #ffffff;
    margin-bottom: 10px;
`;

export default ResultComponent;