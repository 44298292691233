import React, {useState, useEffect} from 'react';
import Styled from 'styled-components';
import ResultComponent from './ResultComponent.tsx';
import {computeDestinationPoint} from 'geolib';
import ResultsContainerComponent from './ResultsContainerComponent.tsx';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grow from '@mui/material/Grow';

interface Location{
    latitude: number;
    longitude: number;
}

const ResultViewComponent = (props) => {
    const [location, setLocation] = useState<Location>({latitude: 0, longitude: 0});//Base coordinate is 0,0
    const [error, setError] = useState<string | null>(null);

    const [offset, setOffset] = useState(0);//By default no offset
    
    const [SWBound, setSWBound] = useState({
        latitude: 0,
        longitude: 0
    });

    const [NEBound, setNEBound] = useState({
        latitude: 0,
        longitude: 0
    });

    const [fetchedJson, setFetchedJson] = useState<any[]>([]);

    //Retrieve the user location on mount
    useEffect(() => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setLocation({ latitude, longitude });
            },
            (error) => {
              setError(error.message);
            }
          );
        } else {
          setError('Geolocation is not supported by your browser');
        }
      }, []);

    //When the user position is updated -> update bounding box
    useEffect(() => {
        //Refresh bounding box coordinates
        var boundingSouthWest = offsetCoordinates(1000, 225);
        var boundingNorthEast = offsetCoordinates(1000, 45);

        setSWBound({
            latitude: boundingSouthWest.latitude,
            longitude: boundingSouthWest.longitude
        });

        setNEBound({
            latitude: boundingNorthEast.latitude,
            longitude: boundingNorthEast.longitude
        });

    }, [location]);

    //When bounding box is updated -> refetch data
    useEffect(() => {
        setFetchedJson([]);//Reset the results

        const fetchOsmData = async (target) => {
                var result = await fetch(
                "https://overpass-api.de/api/interpreter",
                {
                    method: "POST",
                    
                    body: "data="+ encodeURIComponent(`
                        [bbox:${NEBound.latitude}, ${NEBound.longitude},${SWBound.latitude}, ${SWBound.longitude}]
                        
                        [out:json]
                        [timeout:90]
                        ;
                        (
                            node
                                (
                                    ${SWBound.latitude},
                                    ${SWBound.longitude},
                                    ${NEBound.latitude},
                                    ${NEBound.longitude}
                                 )
                                 ["amenity"="${target}"];
                        );
                        out geom;
                    `)
                },
            ).then((data)=>data.json()).then();

            setFetchedJson(prevItems => [...prevItems, ...result.elements]);
        }

        //Run the fetch query
        fetchOsmData("bar")
        fetchOsmData("pub")
        setShowData(true);//The data pulling is done -> show results
    }, [SWBound, NEBound]);

    const [showData, setShowData] = useState(true);

    //Helper function to calculate a coordinate based on offset and bearing
    function offsetCoordinates(_offset : number, bearing : number){
      setOffset(_offset);//Update the current offset value

      const calculatedCoords = computeDestinationPoint(location, offset, bearing);

      return calculatedCoords;
    }

    const handleChange = () => {
        setShowData(!showData);
    }
    
    
    return(
        <>
        <div className="flex flex-col">

        {showData && 
            <Box sx={{height: 1000}}>
                <Box flexDirection='column' sx={{display: 'flex'}}>
                {
                fetchedJson.map((item, index) => (
                    <Grow
                            in={showData}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(showData ? { timeout: (index * 50) } : {})}
                    >
                        <div><ResultComponent tags={item.tags}></ResultComponent></div>
                    </Grow>
                ))}
            </Box>
            </Box>
            
        }
        </div>
        </>
    )

}

export default ResultViewComponent;