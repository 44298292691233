import React from 'react';
import styled from 'styled-components';

const FooterComponent = () => {
    return(
        <>
            <Container className="flex flex-col py-10 text-[#ecf0f1]">
                <p className="underline">Data from OpenStreetMap contributors. May be inaccurate. Verify from other sources.</p>
                <p className="italic">Created by a student from the IT-University of Copenhagen, with assist from @Kjoege</p>
            </Container>
        </>
    )
}

const Container = styled.div`
    height: 60px;
    width: 100%;
    background-color: #34495e;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default FooterComponent;