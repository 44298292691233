import React from 'react';
import styled from 'styled-components';
import bannerImage from './banner.png';

const HeaderComponent = () => {
    return(
        <>
            <Header>
            <a href="https://www.wheretogo.life/">
                <img src={bannerImage} className="h-12"></img>        
            </a>
            
                
            </Header>
        </>
    )
}

const Header = styled.div`
    height: 70px;
    background-color: #ecf0f1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default HeaderComponent;