import React, {useState} from 'react';
import Styled from'styled-components';
import QuizComponent from './Quiz/QuizComponent.tsx';
import ResultViewComponent from './Result/ResultViewComponent.tsx';

const runAlert = () => {
    alert("This site is still under development and is NOT functional.");
}

const MainContentComponent = () =>{
    const [showResults, setShowResults] = useState(false);
    const [selected, setSelected] = useState();

    const updateView = (newSelected) => {
        setSelected(newSelected);
        setShowResults(true);
    }

    //runAlert();

    return(
        <>
            <Main className="flex flex-1 justify-center items-center bg-[#f3f3f5] overflow-auto">
                {!showResults && <QuizComponent onClick={updateView}></QuizComponent>}
                {showResults && <ResultViewComponent selected={selected}/>}
            </Main>
        </>
    )
}

const Main = Styled.div`
    
`;

export default MainContentComponent;