import React, {useState} from 'react';
import Styled from 'styled-components';
import OptionComponent from './OptionComponent.tsx';
import StartComponent from './StartComponent.tsx';
import { faBeer, faGlassWater, faMoneyBill, faMuseum, faToilet, faToiletPaper, faWater } from '@fortawesome/free-solid-svg-icons';

const QuizComponent = ({onClick}) => {
    const updateSelected = (title) => {
        onClick(title);
    }

    return(
        <>
            <Main className="rounded-md">
                <div className="flex justify-center items-center text-xl font-bold pt-5 pb-5">
                    <h1>Hi, what are you looking for?</h1>
                </div>
                <OptionHolder>
                    <OptionComponent title="Bars, pubs & cafe's" icon={faBeer} onClick={updateSelected}></OptionComponent>
                    <OptionComponent title="Museums" icon={faMuseum} onClick={updateSelected}></OptionComponent>
                    <OptionComponent title="Toilets" icon={faToiletPaper} onClick={updateSelected}></OptionComponent>
                    <OptionComponent title="Water fountains" icon={faGlassWater} onClick={updateSelected}></OptionComponent>
                    <OptionComponent title="ATMs" icon={faMoneyBill} onClick={updateSelected}></OptionComponent>
                </OptionHolder>
            </Main>
        </>
    )
}

const Main = Styled.div`
    height: 500px;
    width: 400px;
    background: #ffffff;
`;

const OptionHolder = Styled.div`

`;

export default QuizComponent;