import logo from './logo.svg';
import './App.css';
import HeaderComponent from './Components/HeaderComponent.tsx';
import FooterComponent from './Components/FooterComponent.tsx';
import MainContentComponent from './Components/MainContentComponent.tsx';
import React, {useState} from 'react';

function App() {
  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();

    if(password === "wheretogopass"){
      setIsPasswordCorrect(true);
    } else setIsPasswordCorrect(false);
  };

  return (
    <>
      <div>
      {!isPasswordCorrect ? (
        <form onSubmit={handleSubmit}>
          <label>
            Password:
            <input type="password" value={password} onChange={handlePasswordChange} />
          </label>
          <button type="submit">Submit</button>
        </form>
      ) : (
        <div>
          <p>Password correct! You can now access the protected content.</p>
          {/* Render your protected content here */}
          <div className="flex flex-col h-screen">
        <HeaderComponent/>
        <MainContentComponent/>
        <FooterComponent/>
      </div>
        </div>
      )}
    </div>



      
      
    </>
  );
}

export default App;
